<ul class="footer-links">
    <li class="footer-links__item">
        <a [href]="getServiceLink('bazaCommon.links.termsOfService')">Terms Of Service</a>
    </li>

    <li class="footer-links__item">
        <a [href]="getServiceLink('bazaCommon.links.privacyPolicy')">Privacy Policy</a>
    </li>

    <li class="footer-links__item">
        <a
            [routerLink]="['', { outlets: { modal: ['contact-us'] } }]"
            [state]="{ skipScroll: true }">
            Contact Us
        </a>
    </li>

    <li class="footer-links__item">
        <a [href]="getServiceLink('bazaCommon.links.faq') ?? '/faq'">FAQs</a>
    </li>
</ul>
