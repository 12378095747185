import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Router, UrlCreationOptions } from '@angular/router';
import { JwtService } from '../services/jwt.service';
import { BazaAuthNgConfig } from '../baza-auth-ng.config';
import { BazaError, isBazaErrorResponse } from '@scaliolabs/baza-core-shared';
import { AuthEndpointPaths, AuthErrorCodes, VerifyRequest } from '@scaliolabs/baza-core-shared';
import { BazaAuthDataAccess, BazaDataAccessService } from '@scaliolabs/baza-core-data-access';
import { BazaNgApiOnlineStatusService } from '../../../baza-common/lib/services/baza-ng-api-online-status.service';

import url from 'url';

/**
 * Configuration for JwtHttpInterceptor
 *
 * @see JwtHttpInterceptor
 *
 * @example
 * Default configuration can be replaces with bazaWebBundleConfigBuilder helper.
 *
 * ```typescript
 * const config = bazaWebBundleConfigBuilder().withModuleConfigs({
 *       BazaAuthNgModule: (bundleConfig) => ({
 *           deps: [],
 *           useFactory: () => ({
 *               jwtHttpInterceptorConfig: {
 *                   // Your configuration
 *               },
 *               // Additional required configuration for BazaAuthNgModule
 *           }),
 *       }),
 *   })
 * ```
 */
export class BazaJwtHttpInterceptorConfig {
    /**
     * If JwtHttpInterceptor fails, user will be redirected to specific route.
     */
    redirect?: (request: HttpRequest<any>) => {
        commands: any[];
        navigationExtras?: UrlCreationOptions;
    };
}

/**
 * HTTP Interceptor which should be added at root HttpClient module.
 * Make sure that you HttpClient module will not be re-instanced, unless you'll
 * lose JWT interceptor.
 *
 * JwtHttpInterceptor automatically will try to generate new access token if
 * current one is outdated. If access token is outdated and autoRefreshToken option
 * of BazaAuthNgModule is enabled (be default yes), HTTP interceptor will attempts
 * to fetch new Access Token using Refresh Token stored in same JwtService.
 *
 * Interceptor will automatically redirect user to sign-in page if interceptor
 * was not able to refresh access token or access token is outdated and autoRefreshToken
 * is set to false.
 *
 * Additionally JwtHttpInterceptor uses retry strategy. Interceptor will attempts
 * to verify /  fetch new access token until it will receives a real response from
 * BE. If more than 5 attempts failed, Interceptor will not redirect user to sign in
 * page, but will mark API as offline using BazaNgApiOnlineStatusService service.
 *
 * If tokens are not set in JwtService, Interceptor will not do anything and just
 * pass request as is. Use guards like `JwtRequireUserGuard` to protect your routes.
 *
 * @see JwtService
 * @see BazaNgApiOnlineStatusService
 */
@Injectable()
export class JwtHttpInterceptor implements HttpInterceptor {
    constructor(
        private readonly moduleConfig: BazaAuthNgConfig,
        private readonly router: Router,
        private readonly jwtService: JwtService,
        private readonly appOnlineStatus: BazaNgApiOnlineStatusService,
        private readonly authEndpoint: BazaAuthDataAccess,
        private readonly ngEndpoint: BazaDataAccessService,
    ) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!req.url.startsWith(this.ngEndpoint.baseUrl)) {
            return next.handle(req);
        }

        const parsed = url.parse(req.url);

        // Remove API version from path
        const path = parsed.path
            .split('/')
            .filter((item) => item.search(/v\d/) === -1)
            .join('/');

        if ([AuthEndpointPaths.refreshToken].includes(path as AuthEndpointPaths)) {
            return this.interceptRefreshToken(req, next);
        } else {
            if (this.jwtService.hasJwt()) {
                return this.interceptWithJwt(req, next);
            } else {
                return next.handle(req);
            }
        }
    }

    private interceptRefreshToken(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((refreshErr: HttpErrorResponse) => {
                const refreshBazaErr: BazaError<AuthErrorCodes> = refreshErr.error;

                if (Object.values(AuthErrorCodes).includes(refreshBazaErr.code)) {
                    this.redirectToAuth(req);
                }

                return throwError(refreshErr);
            }),
        );
    }

    private interceptWithJwt(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const parsed = url.parse(req.url);

        const reqWithJwt = () =>
            [AuthEndpointPaths.verify].includes(parsed.path as any)
                ? req
                : req.clone({
                      headers: req.headers.set('Authorization', `Bearer ${this.jwtService.jwt.accessToken}`),
                  });

        return next.handle(reqWithJwt()).pipe(
            tap((response: HttpEvent<unknown>) => {
                if ('status' in response) {
                    if (this.appOnlineStatus) {
                        this.appOnlineStatus.markApiAsOnline();
                    }
                }
            }),
            catchError((err: HttpErrorResponse) => {
                if (!this.moduleConfig.autoRefreshToken) {
                    return throwError(err);
                }

                const bazaErr: BazaError = err.error;

                if (!isBazaErrorResponse(bazaErr)) {
                    return throwError(err);
                }

                if (bazaErr.code !== AuthErrorCodes.AuthInvalidRefreshTokenJwt && Object.values(AuthErrorCodes).includes(bazaErr.code)) {
                    return this.authEndpoint
                        .refreshToken({
                            refreshToken: this.jwtService.jwt.refreshToken,
                        })
                        .pipe(
                            switchMap((refreshResponse) => {
                                this.jwtService.refreshAccessToken(refreshResponse.accessToken);
                                this.jwtService.markJwtAsVerified();

                                if ([AuthEndpointPaths.verify].includes(parsed.path as AuthEndpointPaths)) {
                                    return next.handle(
                                        req.clone({
                                            body: {
                                                ...req.body,
                                                jwt: this.jwtService.jwt.accessToken,
                                            } as VerifyRequest,
                                        }),
                                    );
                                } else {
                                    return next.handle(reqWithJwt());
                                }
                            }),
                            catchError((err) => {
                                console.error(`[JwtHttpInterceptor] Unknown error happened with refreshing token`);
                                console.error(err);

                                this.redirectToAuth(req);

                                return throwError(err);
                            }),
                        );
                } else {
                    return throwError(err);
                }
            }),
        );
    }

    private redirectToAuth(req: HttpRequest<unknown>): void {
        this.jwtService.destroy();

        const urlTree = this.moduleConfig.jwtHttpInterceptorConfig.redirect(req);

        this.router.navigate(urlTree.commands, urlTree.navigationExtras);
    }
}
