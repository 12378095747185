export const VFInvestorEnI18n = {
    title: 'Investor Profile',
    form: {
        fields: {
            isAccreditedInvestor: {
                label: 'Are you an accredited investor?',
                validators: {
                    required: 'Accreditor investor option is required',
                },
                yesOption: {
                    label: 'Yes',
                    descr: 'I have an annual income greater than $200k, joint income greater than $300k, or net worth greater than $1M (excluding primary residence)',
                },
                noOption: {
                    label: 'No',
                    descr: 'I acknowledge that my investment(s) in this offering is less than 10% of my net worth or annual gross income.',
                },
            },
            isAssociatedWithFINRA: {
                label: 'Are you or anyone in your household associated with a FINRA member, organization or the SEC?',
                validators: {
                    required: 'FINRA association option is required',
                },
                yesOption: {
                    label: 'Yes',
                    descr: 'I have an annual income greater than $200k, joint income greater than $300k, or net worth greater than $1M (excluding primary residence)',
                },
                noOption: {
                    label: 'No',
                    descr: 'I acknowledge that my investment(s) in this offering is less than 10% of my net worth or annual gross income.',
                },
            },
            finraRelationship: {
                label: 'Please describe your relationship with such member(s) of your household',
                validators: {
                    maxlength: 'FINRA relationship has to be a maximum of {{ length }} characters',
                    required: 'FINRA relationship is required',
                },
            },
            dwollaConsentProvided: {
                validators: {
                    required: 'Please provide the Dwolla consent before proceeding',
                },
                descr: `By checking this box you agree with our
                <a data-link="tosLink"></a> and
                <a data-link="privacyLink"></a>, as well as our partner Dwolla's
                <a data-link="dwTosLink"></a> and
                <a data-link="dwPrivacyLink"></a>.`,
            },
        },
    },
    steps: {
        backBtnLabel: 'Back',
        confirmBtnLabel: 'Confirm',
    },
    linkConfigs: [
        {
            key: 'tosLink',
            link: 'bazaCommon.links.termsOfService',
            isCMSLink: true,
            text: 'Terms of Service',
        },
        {
            key: 'privacyLink',
            link: 'bazaCommon.links.privacyPolicy',
            isCMSLink: true,
            text: 'Privacy Policy',
        },
        {
            key: 'dwTosLink',
            link: 'bazaCommon.links.dwollaTermsOfService',
            isCMSLink: true,
            text: 'Terms of Service',
        },
        {
            key: 'dwPrivacyLink',
            link: 'bazaCommon.links.dwollaPrivacyPolicy',
            isCMSLink: true,
            text: 'Privacy Policy',
        },
    ],
};
