import { ApiModelProperty } from '@nestjs/swagger/dist/decorators/api-model-property.decorator';
import { IsBoolean, IsInt, IsNotEmpty, IsOptional, IsPositive, IsString } from 'class-validator';
import { Observable } from 'rxjs';
import { NorthCapitalAccountId, OfferingId, PartyId } from '../../../transact-api';

export enum NcSyncEndpointPaths {
    syncAllInvestorAccounts = '/baza-nc/sync/syncAllInvestorAccounts',
    syncInvestorAccount = '/baza-nc/sync/syncInvestorAccount',
    syncInvestorAccountPaymentMethods = '/baza-nc/sync/syncInvestorAccountPaymentMethods',
    syncAllInvestorAccountsPaymentMethods = '/baza-nc/sync/syncAllInvestorAccountsPaymentMethods',
    syncTransactions = '/baza-nc/sync/syncTransactions',
    syncOfferingInvestorAccounts = '/baza-nc/offerings/syncOfferingInvestorAccounts',
    syncPercentsFunded = '/baza-nc/sync/syncPercentsFunded',
    syncPercentsFundedOfOffering = '/baza-nc/sync/syncPercentsFundedOfOffering',
    syncRemove = '/baza-nc/sync/syncRemove',
}

export interface BazaNcSyncEndpoint {
    syncAllInvestorAccounts(): Promise<void> | Observable<void>;
    syncInvestorAccount(
        request: NcSyncInvestorAccountRequest,
    ): Promise<NcSyncInvestorAccountResponse> | Observable<NcSyncInvestorAccountResponse>;
    syncInvestorAccountPaymentMethods(request: NcSyncInvestorAccountPaymentMethodsRequest): Promise<void> | Observable<void>;
    syncAllInvestorAccountsPaymentMethods(): Promise<void> | Observable<void>;
    syncTransactions(request: NcSyncTransactionsRequest): Promise<void> | Observable<void>;
    syncPercentsFunded(): Promise<void> | Observable<void>;
    syncPercentsFundedOfOffering(request: NcSyncPercentsFundedForOfferingRequest): Promise<void> | Observable<void>;
    syncRemove(
        request: NcSyncRemoveInvestorAccountRequest,
    ): Promise<NcSyncRemoveInvestorAccountResponse> | Observable<NcSyncRemoveInvestorAccountResponse>;
}

export class NcSyncInvestorAccountRequest {
    @ApiModelProperty({
        description: 'Account Id',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    userId: number;

    @ApiModelProperty({
        description: 'NC Account Id',
        type: 'string',
    })
    @IsString()
    @IsNotEmpty()
    ncAccountId: NorthCapitalAccountId;

    @ApiModelProperty({
        description: 'NC Party Id',
        type: 'string',
    })
    @IsString()
    @IsNotEmpty()
    ncPartyId: PartyId;

    @ApiModelProperty({
        description: 'If enabled will sync offering percent funded',
        type: 'boolean',
        default: true,
    })
    @IsBoolean()
    @IsOptional()
    syncOfferingPercentFunded?: boolean = true;
}

export class NcSyncInvestorAccountResponse {
    @ApiModelProperty({
        description: 'Email',
    })
    email: string;

    @ApiModelProperty({
        description: 'Offering ID',
        type: 'string',
        isArray: true,
    })
    affectedOfferingIds: Array<OfferingId>;

    @ApiModelProperty({
        description: 'Is Bank Account Linked?',
    })
    isBankAccountLinked: boolean;

    @ApiModelProperty({
        description: 'Is Credit Card Linked?',
    })
    isCreditCardLinked: boolean;

    @ApiModelProperty({
        description: 'Count transactions found',
    })
    countTransactions: number;
}

export class NcSyncInvestorAccountPaymentMethodsRequest {
    @ApiModelProperty({
        description: 'Account Id',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    userId: number;
}


export class NcSyncOfferingInvestorAccountsRequest {
    @ApiModelProperty({
        description: 'North Capital Offering ID',
    })
    @IsString()
    @IsNotEmpty()
    ncOfferingId: string;

    @ApiModelProperty({
        description: 'North Capital Offering ID',
        default: false,
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    async?: boolean = false;
}

export class NcSyncTransactionsRequest {
    @ApiModelProperty({
        description: 'Account Id',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    userId: number;

    @ApiModelProperty({
        description: 'Execute Sync in Async mode',
        default: false,
        required: false,
    })
    @IsBoolean()
    @IsOptional()
    async?: boolean;
}

export class NcSyncPercentsFundedForOfferingRequest {
    @ApiModelProperty({
        description: 'Offering ID',
        type: 'string',
    })
    @IsString()
    @IsString()
    @IsNotEmpty()
    offeringId: OfferingId;
}

export class NcSyncRemoveInvestorAccountRequest {
    @ApiModelProperty({
        description: 'Investor Account Id',
    })
    @IsPositive()
    @IsInt()
    @IsNotEmpty()
    investorAccountId: number;
}

export class NcSyncRemoveInvestorAccountResponse {
    @ApiModelProperty({
        description: 'Offering ID',
        type: 'string',
        isArray: true,
    })
    affectedOfferingIds: Array<OfferingId>;
}


export type NcSyncOfferingInvestorAccountsResponse = void;
